export type Choice = {
	label: string;
	value: string;
	[other: string]: string;
};

export const defaultValuesTipologias = [
	{
		value: "Almacén",
		label: "Edificio de Almacén",
	},
	{
		value: "Dependencia",
		label: "Dependencia en otros edificios",
	},
	{
		value: "Edificio principal de Base de mantenimiento",
		label: "Edificio principal de Base de mantenimiento",
	},
	{
		value: "Edificio técnico",
		label: "Edificio técnico",
	},
	{
		value: "Caseta",
		label: "Edificio tipo Caseta",
	},
	{
		value: "Oficinas",
		label: "Edificio de Oficinas",
	},
	{
		value: "Subestación",
		label: "Edificio de Subestación",
	},
	{
		value: "Garaje",
		label: "Edificio tipo Garaje",
	},
	{
		value: "Taller",
		label: "Edificio de Taller",
	},
	{
		value: "Otro",
		label: "Otro tipo de edificios",
	},
];
export const defaultValuesUsos = [
	{
		value: "Almacén",
		label: "Uso Almacén",
	},

	{
		value: "Garaje",
		label: "Uso Garaje",
	},
	{
		value: "Vestuarios",
		label: "Uso Vestuarios",
	},
	{
		value: "Laboratorios",
		label: "Uso Laboratorios",
	},
	{
		value: "Enclavamiento",
		label: "Uso Enclavamiento",
	},
	{
		value: "Oficina",
		label: "Uso Oficina",
	},
	{
		value: "Taller",
		label: "Uso Taller",
	},
	{
		value: "Aseos",
		label: "Uso Aseos",
	},
	{
		value: "Cocina/Comedor",
		label: "Uso Cocina/Comedor",
	},
	{
		value: "BTS",
		label: "Uso BTS (Base Transceiver Station)",
	},
	{
		value: "Autotransformador",
		label: "Uso Autotransformador",
	},
	{
		value: "Grupo electrógeno",
		label: "Uso Grupo de electrógeno",
	},
	{
		value: "Cuarto técnico",
		label: "Uso Cuarto técnico",
	},
	{
		value: "Equipo LAC",
		label: "Uso Equipo LAC",
	},
	{
		value: "Caseta de Medida Fiscal",
		label: "Uso Caseta de Medida Fiscal",
	},
	{
		value: "Caseta operadora",
		label: "Uso Caseta operadora",
	},
	{
		value: "Caseta de túnel",
		label: "Uso Caseta de túnel",
	},
	{
		value: "Uso Caseta de tren Tierra",
		label: "Uso Caseta de tren Tierra",
	},
	{
		value: "Cambiador de ancho",
		label: "Uso Cambiador de ancho",
	},
	// {
	// 	value: "Dependencia fuera de traza",
	// 	label: "Uso Dependencia fuera de traza",
	// },
	{
		value: "Centro de transformación",
		label: "Uso Centro de transformación",
	},
	{
		value: "Centro de regulación de circulación",
		label: "Uso Centro de regulación de circulación",
	},
	{
		value: "Centro de instalaciones",
		label: "Uso Centro de instalaciones",
	},
	{
		value: "Subestación eléctrica",
		label: "Uso Subestación Eléctrica",
	},
	{
		value: "Sala de comunicaciones",
		label: "Uso Sala Comunicaciones",
	},

	{
		value: "Otro",
		label: "Otros Usos",
	},
];
export const defaultValuesSistemas = [
	{ label: "1.Sistema estructural", value: "1.Sistema estructural" },
	{ label: "2.Sistema de envolventes", value: "2.Sistema de envolventes" },
	{ label: "3.Compartimentación", value: "3.Compartimentación" },
	{ label: "4.Sistema de acabados", value: "4.Sistema de acabados" },
	{
		label: "5.Resguardo y protección de los usuarios",
		value: "5.Resguardo y protección de los usuarios",
	},
	{ label: "6.Equipamiento", value: "6.Equipamiento" },
	{ label: "7.Urbanización y áreas verdes", value: "7.Urbanización y áreas verdes" },
	{
		label: "8.Sistema de evacuación de aguas",
		value: "8.Sistema de evacuación de aguas",
	},
	{
		label: "9.Sistema de suministro de aguas",
		value: "9.Sistema de suministro de aguas",
	},
	{
		label: "10.Instalación eléctrica AT",
		value: "10.Instalación eléctrica AT",
	},
	{
		label: "11.Instalación eléctrica BT",
		value: "11.Instalación eléctrica BT",
	},
	{
		label: "12.Sistema de carga de vehículos eléctricos",
		value: "12.Sistema de carga de vehículos eléctricos",
	},
	{
		label: "13.Protección contra el rayo",
		value: "13.Protección contra el rayo",
	},
	{
		label: "14.Instalaciones térmicas de los edificios",
		value: "14.Instalaciones térmicas de los edificios",
	},
	{ label: "15.Instalación solar térmica", value: "15.Instalación solar térmica" },
	{ label: "16.Instalación frigorífica", value: "16.Instalación frigorífica" },
	{ label: "17.Iluminación", value: "17.Iluminación" },
	{
		label: "18.Instalación de protección contra incendios",
		value: "18.Instalación de protección contra incendios",
	},
	{ label: "19.Instalación petrolífera", value: "19.Instalación petrolífera" },
	{ label: "20.Telecomunicaciones", value: "20.Telecomunicaciones" },
	{
		label: "21.Instalación de gases combustibles",
		value: "21.Instalación de gases combustibles",
	},
	{
		label: "22.Instalación de equipos a presión",
		value: "22.Instalación de equipos a presión",
	},
	{ label: "23.Equipos de elevación", value: "23.Equipos de elevación" },
	{
		label: "24.Medios de comunicación vertical",
		value: "24.Medios de comunicación vertical",
	},
	{
		label: "25.Instalación solar fotovoltáica",
		value: "25.Instalación solar fotovoltáica",
	},
	{
		label: "26.Instalación de intercambio geotérmico",
		value: "26.Instalación de intercambio geotérmico",
	},
	{ label: "27.Accesos automáticos", value: "27.Accesos automáticos" },
];

export const defaultValuesElementos = [
	{
		label: "Sistemas de contencion",
		value: "Sistemas de contencion",
		subsistema: "Cimentación",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Cimentacion superficial",
		value: "Cimentacion superficial",
		subsistema: "Cimentación",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Cimentacion profunda",
		value: "Cimentacion profunda",
		subsistema: "Cimentación",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Muros de carga",
		value: "Muros de carga",
		subsistema: "Estructura vertical",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Pilares",
		value: "Pilares",
		subsistema: "Estructura vertical",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Forjados tipo: vigas",
		value: "Forjados tipo: vigas",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Forjados tipo: viguetas",
		value: "Forjados tipo: viguetas",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Forjados tipo: entrevigado",
		value: "Forjados tipo: entrevigado",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Forjados tipo: otros",
		value: "Forjados tipo: otros",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Forjado suelo",
		value: "Forjado suelo",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Uniones y articulaciones de conexión",
		value: "Uniones y articulaciones de conexión",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Escaleras",
		value: "Escaleras",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Rampas",
		value: "Rampas",
		subsistema: "Estructura horizontal",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Cubierta forjado",
		value: "Cubierta forjado",
		subsistema: "Estructuras de cubiertas",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Estructuras trianguladas",
		value: "Estructuras trianguladas",
		subsistema: "Estructuras de cubiertas",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Cubierta vigas",
		value: "Cubierta vigas",
		subsistema: "Estructuras de cubiertas",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Cubierta formacion",
		value: "Cubierta formacion",
		subsistema: "Estructuras de cubiertas",
		sistema: "1.Sistema estructural",
	},
	{
		label: "Principal",
		value: "Principal",
		subsistema: "Cerramientos y fachadas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Medianerias",
		value: "Medianerias",
		subsistema: "Cerramientos y fachadas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Fachadas interiores",
		value: "Fachadas interiores",
		subsistema: "Cerramientos y fachadas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Otras fachadas",
		value: "Otras fachadas",
		subsistema: "Cerramientos y fachadas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Ventanas",
		value: "Ventanas",
		subsistema: "Huecos y carpinterias exteriores",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Muro cortina",
		value: "Muro cortina",
		subsistema: "Huecos y carpinterias exteriores",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Puertas exteriores",
		value: "Puertas exteriores",
		subsistema: "Huecos y carpinterias exteriores",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Azotea/cubierta plana",
		value: "Azotea/cubierta plana",
		subsistema: "Cubiertas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Cubierta inclinada",
		value: "Cubierta inclinada",
		subsistema: "Cubiertas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Elementos singulares",
		value: "Elementos singulares",
		subsistema: "Cubiertas",
		sistema: "2.Sistema de envolventes",
	},
	{
		label: "Tabiqueria interior",
		value: "Tabiqueria interior",
		subsistema: "Tabiqueria y compartimentacion",
		sistema: "3.Compartimentación",
	},
	{
		label: "Mamparas",
		value: "Mamparas",
		subsistema: "Tabiqueria y compartimentacion",
		sistema: "3.Compartimentación",
	},
	{
		label: "Aislamiento acustico",
		value: "Aislamiento acustico",
		subsistema: "Tabiqueria y compartimentacion",
		sistema: "3.Compartimentación",
	},
	{
		label: "Puertas interiores",
		value: "Puertas interiores",
		subsistema: "Carpinterias interiores",
		sistema: "3.Compartimentación",
	},
	{
		label: "Ventanas interiores",
		value: "Ventanas interiores",
		subsistema: "Carpinterias interiores",
		sistema: "3.Compartimentación",
	},
	{
		label: "Cerramientos",
		value: "Cerramientos",
		subsistema: "Vallas y cercados",
		sistema: "5.Resguardo y protección de los usuarios",
	},
	{
		label: "Puertas cerramientos",
		value: "Puertas cerramientos",
		subsistema: "Vallas y cercados",
		sistema: "5.Resguardo y protección de los usuarios",
	},
	{
		label: "Otras vallas y cercados",
		value: "Otras vallas y cercados",
		subsistema: "Vallas y cercados",
		sistema: "5.Resguardo y protección de los usuarios",
	},
	{
		label: "Anclaje carros",
		value: "Anclaje carros",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Carro portaequipajes",
		value: "Carro portaequipajes",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Banco",
		value: "Banco",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Papelera",
		value: "Papelera",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Apoyo isquiático",
		value: "Apoyo isquiático",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Vitrina información",
		value: "Vitrina información",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Poste separador",
		value: "Poste separador",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Consigna",
		value: "Consigna",
		subsistema: "Mobiliario espacios publicos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Armario",
		value: "Armario",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Mueble",
		value: "Mueble",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Mesa",
		value: "Mesa",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Silla",
		value: "Silla",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Elemento complementario",
		value: "Elemento complementario",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Electrodoméstico",
		value: "Electrodoméstico",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Contenedor",
		value: "Contenedor",
		subsistema: "Mobiliario espacios administrativos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Espejo",
		value: "Espejo",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Jabonera",
		value: "Jabonera",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Secador manos eléctrico",
		value: "Secador manos eléctrico",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Portarrollos",
		value: "Portarrollos",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Cubo higiénico sanitario",
		value: "Cubo higiénico sanitario",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Armario ropero metálico",
		value: "Armario ropero metálico",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Banco vestuario",
		value: "Banco vestuario",
		subsistema: "Equipamiento aseos y vestuarios",
		sistema: "6.Equipamiento",
	},
	{
		label: "Plataforma elevadora pmr",
		value: "Plataforma elevadora pmr",
		subsistema: "Equipamiento accesibilidad",
		sistema: "6.Equipamiento",
	},
	{
		label: "Silla de ruedas estaciones",
		value: "Silla de ruedas estaciones",
		subsistema: "Equipamiento accesibilidad",
		sistema: "6.Equipamiento",
	},
	{
		label: "Rampa portátil",
		value: "Rampa portátil",
		subsistema: "Equipamiento accesibilidad",
		sistema: "6.Equipamiento",
	},
	{
		label: "Salvaescaleras",
		value: "Salvaescaleras",
		subsistema: "Equipamiento accesibilidad",
		sistema: "6.Equipamiento",
	},
	{
		label: "Torno acceso viajeros a estacion",
		value: "Torno acceso viajeros a estacion",
		subsistema: "Control de accesos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Torno control de accesos edificios",
		value: "Torno control de accesos edificios",
		subsistema: "Control de accesos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Barrera control parking",
		value: "Barrera control parking",
		subsistema: "Control de accesos",
		sistema: "6.Equipamiento",
	},
	{
		label: "Señaletica",
		value: "Señaletica",
		subsistema: "Señalización y señalética",
		sistema: "6.Equipamiento",
	},
	{
		label: "Banda led borde de anden",
		value: "Banda led borde de anden",
		subsistema: "Señalización y señalética",
		sistema: "6.Equipamiento",
	},
	{
		label: "Equipamiento industrial",
		value: "Equipamiento industrial",
		subsistema: "Equipamiento industrial y de mantenimiento",
		sistema: "6.Equipamiento",
	},
	{
		label: "Maquinaria de mantenimiento",
		value: "Maquinaria de mantenimiento",
		subsistema: "Equipamiento industrial y de mantenimiento",
		sistema: "6.Equipamiento",
	},
	{
		label: "Desfibrilador",
		value: "Desfibrilador",
		subsistema: "Equipamiento medico-sanitario",
		sistema: "6.Equipamiento",
	},
	{
		label: "Mobiliario",
		value: "Mobiliario",
		subsistema: "Equipamiento medico-sanitario",
		sistema: "6.Equipamiento",
	},
	{
		label: "Equipo",
		value: "Equipo",
		subsistema: "Equipamiento medico-sanitario",
		sistema: "6.Equipamiento",
	},
	{
		label: "Linea de vida",
		value: "Linea de vida",
		subsistema: "Sistemas anticaidas",
		sistema: "6.Equipamiento",
	},
	{
		label: "Barrera física",
		value: "Barrera física",
		subsistema: "Sistemas anticaidas",
		sistema: "6.Equipamiento",
	},
	{
		label: "Mobiliario",
		value: "Mobiliario",
		subsistema: "Equipamiento formación",
		sistema: "6.Equipamiento",
	},
	{
		label: "Equipo",
		value: "Equipo",
		subsistema: "Equipamiento formación",
		sistema: "6.Equipamiento",
	},
	{
		label: "Mobiliario",
		value: "Mobiliario",
		subsistema: "Equipamiento residencia de formación",
		sistema: "6.Equipamiento",
	},
	{
		label: "Equipo",
		value: "Equipo",
		subsistema: "Equipamiento residencia de formación",
		sistema: "6.Equipamiento",
	},
	{
		label: "Acometida",
		value: "Acometida",
		subsistema: "Elementos enterrados",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Fosa séptica",
		value: "Fosa séptica",
		subsistema: "Elementos enterrados",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Arquetas",
		value: "Arquetas",
		subsistema: "Elementos enterrados",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Bajantes pluviales",
		value: "Bajantes pluviales",
		subsistema: "Canalizaciones verticales",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Bajantes aguas grises",
		value: "Bajantes aguas grises",
		subsistema: "Canalizaciones verticales",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Colectores",
		value: "Colectores",
		subsistema: "Canalizaciones horizontales",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Bombas",
		value: "Bombas",
		subsistema: "Otros elementos del sistema",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Sifones",
		value: "Sifones",
		subsistema: "Otros elementos del sistema",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Imbornales",
		value: "Imbornales",
		subsistema: "Otros elementos del sistema",
		sistema: "8.Sistema de evacuación de aguas",
	},
	{
		label: "Acometidas",
		value: "Acometidas",
		subsistema: "Instalación general enlace",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Contadores",
		value: "Contadores",
		subsistema: "Instalación general enlace",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Equipos de cloración",
		value: "Equipos de cloración",
		subsistema: "Sistemas de tratamiento de agua",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Otros sistemas de tratamiento de agua",
		value: "Otros sistemas de tratamiento de agua",
		subsistema: "Sistemas de tratamiento de agua",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Depósitos afs",
		value: "Depósitos afs",
		subsistema: "Depositos de acumulación",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Depósitos acs",
		value: "Depósitos acs",
		subsistema: "Depositos de acumulación",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Tuberias afs",
		value: "Tuberias afs",
		subsistema: "Canalizaciones",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Tuberias acs",
		value: "Tuberias acs",
		subsistema: "Canalizaciones",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Montantes",
		value: "Montantes",
		subsistema: "Canalizaciones",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Lavabo",
		value: "Lavabo",
		subsistema: "Aparatos sanitarios",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Inodoro",
		value: "Inodoro",
		subsistema: "Aparatos sanitarios",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Urinario",
		value: "Urinario",
		subsistema: "Aparatos sanitarios",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Ducha/bañera",
		value: "Ducha/bañera",
		subsistema: "Aparatos sanitarios",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Vertedero",
		value: "Vertedero",
		subsistema: "Aparatos sanitarios",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Valvulas y llaves",
		value: "Valvulas y llaves",
		subsistema: "Otros elementos del sistema",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Grifos",
		value: "Grifos",
		subsistema: "Otros elementos del sistema",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Mecanismos",
		value: "Mecanismos",
		subsistema: "Otros elementos del sistema",
		sistema: "9.Sistema de suministro de aguas",
	},
	{
		label: "Disyuntor",
		value: "Disyuntor",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Enclavamiento electrico",
		value: "Enclavamiento electrico",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Enclavamiento mecanico",
		value: "Enclavamiento mecanico",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Fusible",
		value: "Fusible",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Interruptor alta tension",
		value: "Interruptor alta tension",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Interruptor baja tension",
		value: "Interruptor baja tension",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Rele de proteccion",
		value: "Rele de proteccion",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Ruptoseccionador",
		value: "Ruptoseccionador",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Seccionador",
		value: "Seccionador",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Sistema conmutación automático",
		value: "Sistema conmutación automático",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Transformador de protección y medida",
		value: "Transformador de protección y medida",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Celda de linea",
		value: "Celda de linea",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Celda de transformador",
		value: "Celda de transformador",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Transformador de potencia",
		value: "Transformador de potencia",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Cuadro eléctrico centro de transformacion",
		value: "Cuadro eléctrico centro de transformacion",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Letreros y elementos de informacion",
		value: "Letreros y elementos de informacion",
		subsistema: "Centros de transformacion",
		sistema: "10.Instalación eléctrica AT",
	},
	{
		label: "Caja general de proteccion (cgp)",
		value: "Caja general de proteccion (cgp)",
		subsistema: "Red distribucion bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Interruptor diferencial",
		value: "Interruptor diferencial",
		subsistema: "Red distribucion bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Interruptor automático al principio de los circuitos de servicios comunes",
		value: "Interruptor automático al principio de los circuitos de servicios comunes",
		subsistema: "Red distribucion bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Fusible al inicio de las derivaciones individuales",
		value: "Fusible al inicio de las derivaciones individuales",
		subsistema: "Red distribucion bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Otros elementos red de distribución bt",
		value: "Otros elementos red de distribución bt",
		subsistema: "Red distribucion bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Protecciones / calibre",
		value: "Protecciones / calibre",
		subsistema: "Cuadro eléctrico",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Circuitos eléctricos",
		value: "Circuitos eléctricos",
		subsistema: "Cuadro eléctrico",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Cuadro de mando y control",
		value: "Cuadro de mando y control",
		subsistema: "Cuadro eléctrico",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Elementos de distribución eléctrica",
		value: "Elementos de distribución eléctrica",
		subsistema: "Distribución y canalizaciones bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Obra civil asociada a cableado",
		value: "Obra civil asociada a cableado",
		subsistema: "Distribución y canalizaciones bt",
		sistema: "11.Instalación eléctrica BT",
	},
	{
		label: "Equipos generadores de calor",
		value: "Equipos generadores de calor",
		subsistema: "Calefacción",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos emisores de calor",
		value: "Equipos emisores de calor",
		subsistema: "Calefacción",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos de bombeo",
		value: "Equipos de bombeo",
		subsistema: "Calefacción",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos generadores",
		value: "Equipos generadores",
		subsistema: "Climatización",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos emisores",
		value: "Equipos emisores",
		subsistema: "Climatización",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos de ventilación",
		value: "Equipos de ventilación",
		subsistema: "Ventilación",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipo recuperador de calor",
		value: "Equipo recuperador de calor",
		subsistema: "Ventilación",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Equipos de cogeneración",
		value: "Equipos de cogeneración",
		subsistema: "Cogeneración",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Linea frigorífica",
		value: "Linea frigorífica",
		subsistema: "Red de tuberias",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Linea hidráulica",
		value: "Linea hidráulica",
		subsistema: "Red de tuberias",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Conductos aire",
		value: "Conductos aire",
		subsistema: "Sistema de distribución y elementos de difusión de aire",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Compuertas",
		value: "Compuertas",
		subsistema: "Sistema de distribución y elementos de difusión de aire",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Silenciadores",
		value: "Silenciadores",
		subsistema: "Sistema de distribución y elementos de difusión de aire",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Reguladores",
		value: "Reguladores",
		subsistema: "Sistema de distribución y elementos de difusión de aire",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Rejillas y difusores",
		value: "Rejillas y difusores",
		subsistema: "Sistema de distribución y elementos de difusión de aire",
		sistema: "14.Instalaciones térmicas de los edificios",
	},
	{
		label: "Captadores",
		value: "Captadores",
		subsistema: "Sistema de captación",
		sistema: "15.Instalación solar térmica",
	},
	{
		label: "Canalizaciones",
		value: "Canalizaciones",
		subsistema: "Sistema de distribución",
		sistema: "15.Instalación solar térmica",
	},
	{
		label: "Intercambiador",
		value: "Intercambiador",
		subsistema: "Sistema de distribución",
		sistema: "15.Instalación solar térmica",
	},
	{
		label: "Equipos de bombeo",
		value: "Equipos de bombeo",
		subsistema: "Sistema de distribución",
		sistema: "15.Instalación solar térmica",
	},
	{
		label: "Acumuladores",
		value: "Acumuladores",
		subsistema: "Sistema de acumulación",
		sistema: "15.Instalación solar térmica",
	},
	{
		label: "Luminaria",
		value: "Luminaria",
		subsistema: "Iluminación y focos",
		sistema: "17.Iluminación",
	},
	{
		label: "Lámpara",
		value: "Lámpara",
		subsistema: "Iluminación y focos",
		sistema: "17.Iluminación",
	},
	{
		label: "Interruptores",
		value: "Interruptores",
		subsistema: "Elementos de encendido",
		sistema: "17.Iluminación",
	},
	{
		label: "Torres iluminacion",
		value: "Torres iluminacion",
		subsistema: "Instalaciones exteriores de iluminación",
		sistema: "17.Iluminación",
	},
	{
		label: "Farolas y báculos",
		value: "Farolas y báculos",
		subsistema: "Instalaciones exteriores de iluminación",
		sistema: "17.Iluminación",
	},
	{
		label: "Alumbrado de evacuación",
		value: "Alumbrado de evacuación",
		subsistema: "Alumbrado de emergencia",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Alumbrado de ambiente o antipánico",
		value: "Alumbrado de ambiente o antipánico",
		subsistema: "Alumbrado de emergencia",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Señal",
		value: "Señal",
		subsistema: "Sistema de señalización",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Balizamiento",
		value: "Balizamiento",
		subsistema: "Sistema de señalización",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Plano de evacuación",
		value: "Plano de evacuación",
		subsistema: "Sistema de señalización",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Puerta resistente al fuego",
		value: "Puerta resistente al fuego",
		subsistema: "Otros elementos protección contra incendios",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Cortina sectorización",
		value: "Cortina sectorización",
		subsistema: "Otros elementos protección contra incendios",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Compuerta de conducto",
		value: "Compuerta de conducto",
		subsistema: "Otros elementos protección contra incendios",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Zona de refugio",
		value: "Zona de refugio",
		subsistema: "Otros elementos protección contra incendios",
		sistema: "18.Instalación de protección contra incendios",
	},
	{
		label: "Regulación y control de combustible",
		value: "Regulación y control de combustible",
		subsistema: "Equipo de medida",
		sistema: "19.Instalación petrolífera",
	},
	{
		label: "Rack",
		value: "Rack",
		subsistema: "Equipos principales de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Repartidor",
		value: "Repartidor",
		subsistema: "Equipos principales de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Switch",
		value: "Switch",
		subsistema: "Equipos principales de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Antena",
		value: "Antena",
		subsistema: "Equipos principales de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Cable radiante",
		value: "Cable radiante",
		subsistema: "Equipos secundarios de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Elementos distribución señal telecom.",
		value: "Elementos distribución señal telecom.",
		subsistema: "Distribución de señal de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Cableado de fibra óptica",
		value: "Cableado de fibra óptica",
		subsistema: "Distribución de señal de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Cableado de cuadrete",
		value: "Cableado de cuadrete",
		subsistema: "Distribución de señal de telecomunicaciones",
		sistema: "20.Telecomunicaciones",
	},
	{
		label: "Regulación y control de gases",
		value: "Regulación y control de gases",
		subsistema: "Equipo de medida",
		sistema: "21.Instalación de gases combustibles",
	},
	{
		label: "Regulación y control",
		value: "Regulación y control",
		subsistema: "Equipo de medida",
		sistema: "22.Instalación de equipos a presión",
	},
	{ label: "Otro", value: "Otro" },
];
export const defaultValuesJefaturas = [
	{
		label: "Jef.Base. Brihuega-AV",
		value: "Jef.Base. Brihuega-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Calatayud-AV",
		value: "Jef.Base. Calatayud-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Montagut-AV",
		value: "Jef.Base. Montagut-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. San Feliu de Buixalleu-AV",
		value: "Jef.Base. San Feliu de Buixalleu-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jef.Base. Villafranca del Penedes-AV",
		value: "Jef.Base. Villafranca del Penedes-AV",
		parent: "G.Mto.A.V. Noreste",
	},
	{
		label: "Jefatura Premantenimiento O Irixo",
		value: "Jefatura Premantenimiento O Irixo",
		parent: "G.Mto.A.V. Noroeste",
	},
	{
		label: "Jef.Base. La Hiniesta-AV",
		value: "Jef.Base. La Hiniesta-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Olmedo-AV",
		value: "Jef.Base. Olmedo-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Villada-AV",
		value: "Jef.Base. Villada-AV",
		parent: "G.Mto.A.V. Norte",
	},
	{
		label: "Jef.Base. Gabaldon-AV",
		value: "Jef.Base. Gabaldon-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Monforte del Cid-AV",
		value: "Jef.Base. Monforte del Cid-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Requena-AV",
		value: "Jef.Base. Requena-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Villarubia-AV",
		value: "Jef.Base. Villarubia-AV",
		parent: "G.Mto.A.V. Este",
	},
	{
		label: "Jef.Base. Antequera-AV",
		value: "Jef.Base. Antequera-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Calatrava-AV",
		value: "Jef.Base. Calatrava-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Hornachuelos-AV",
		value: "Jef.Base. Hornachuelos-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Jef.Base. Mora-AV",
		value: "Jef.Base. Mora-AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Señalizacion - GEA - AV",
		value: "Señalizacion - GEA - AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "Telecomunicaciones - GEA - AV",
		value: "Telecomunicaciones - GEA - AV",
		parent: "G.Mto.A.V.Sur",
	},
	{
		label: "J.A.M. BARCELONA",
		value: "J.A.M. BARCELONA",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "J.A.M. ZARAGOZA",
		value: "J.A.M. ZARAGOZA",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "Cabecera Gerencia Noreste",
		value: "Cabecera Gerencia Noreste",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "Jef.Coord.Inver.Noreste",
		value: "Jef.Coord.Inver.Noreste",
		parent: "RC - Subdirección NORESTE",
	},
	{
		label: "J.A.M. BURGOS",
		value: "J.A.M. BURGOS",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "J.A.M. VALLADOLID",
		value: "J.A.M. VALLADOLID",
		parent: "RC - Subdirección NORTE",
	},
	{ label: "J.A.M. PAMPLONA", value: "J.M. IRÚN", parent: "RC - Subdirección NORTE" },
	{
		label: "Cabecera Gerencia Norte",
		value: "Cabecera Gerencia Norte",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "Jef.Coord.Inver.Norte",
		value: "Jef.Coord.Inver.Norte",
		parent: "RC - Subdirección NORTE",
	},
	{
		label: "J.A.M. CÓRDOBA",
		value: "J.A.M. CÓRDOBA",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.A.M. SEVILLA",
		value: "J.A.M. SEVILLA",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.M. LINARES-BAEZA",
		value: "J.M. LINARES-BAEZA",
		parent: "RC - Subdirección SUR",
	},
	{ label: "J.M. MÉRIDA", value: "J.M. MÉRIDA", parent: "RC - Subdirección SUR" },
	{
		label: "Cabecera Gerencia Sur",
		value: "Cabecera Gerencia Sur",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "Jef.Coord.Inver.Sur",
		value: "Jef.Coord.Inver.Sur",
		parent: "RC - Subdirección SUR",
	},
	{
		label: "J.A.M. VALENCIA",
		value: "J.A.M. VALENCIA",
		parent: "RC - Subdirección ESTE",
	},
	{ label: "J.M. ALBACETE", value: "J.M. ALBACETE", parent: "RC - Subdirección ESTE" },
	{
		label: "Cabecera Gerencia Este",
		value: "Cabecera Gerencia Este",
		parent: "RC - Subdirección ESTE",
	},
	{
		label: "Jef.Coord.Inver.Este",
		value: "Jef.Coord.Inver.Este",
		parent: "RC - Subdirección ESTE",
	},
	{
		label: "J.A.M. MADRID-NORTE",
		value: "J.A.M. MADRID-NORTE",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "J.A.M. MADRID-SUR",
		value: "J.A.M. MADRID-SUR",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "Cabecera Gerencia Centro",
		value: "Cabecera Gerencia Centro",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "Jef.Coord.Inver.Centro",
		value: "Jef.Coord.Inver.Centro",
		parent: "RC - Subdirección CENTRO",
	},
	{
		label: "J.A.M. ORENSE",
		value: "J.A.M. ORENSE",
		parent: "RC - Subdirección NOROESTE",
	},
	{ label: "J.A.M. LEÓN", value: "J.A.M. LEÓN", parent: "RC - Subdirección NOROESTE" },
	{
		label: "J.M. SALAMANCA",
		value: "J.M. SALAMANCA",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Cabecera Gerencia Noroeste",
		value: "Cabecera Gerencia Noroeste",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Jef.Coord.Inver.Noroeste",
		value: "Jef.Coord.Inver.Noroeste",
		parent: "RC - Subdirección NOROESTE",
	},
	{
		label: "Otro",
		value: "Otro",
		parent: "Otro",
	},
];
export const rawDefaults: Record<string, (string | Choice)[]> = {
	ambito: [
		{ label: "AV - Todos los ejes", value: "AV - Todos los ejes" },
		{
			label: "G.Mto.A.V. Este",
			value: "G.Mto.A.V. Este",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Norte",
			value: "G.Mto.A.V. Norte",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Noreste",
			value: "G.Mto.A.V. Noreste",
			red: "AV",
		},
		{
			label: "G.Mto.A.V. Noroeste",
			value: "G.Mto.A.V. Noroeste",
			red: "AV",
		},
		{
			label: "G.Mto.A.V.Sur",
			value: "G.Mto.A.V.Sur",
			red: "AV",
		},
		{
			label: "RC - Todas las subdirecciones",
			value: "RC - Todas las subdirecciones",
		},
		{
			label: "RC - Subdirección CENTRO",
			value: "RC - Subdirección CENTRO",
			red: "RC",
		},
		{
			label: "RC - Subdirección ESTE",
			value: "RC - Subdirección ESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NORESTE",
			value: "RC - Subdirección NORESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NOROESTE",
			value: "RC - Subdirección NOROESTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección NORTE",
			value: "RC - Subdirección NORTE",
			red: "RC",
		},
		{
			label: "RC - Subdirección SUR",
			value: "RC - Subdirección SUR",
			red: "RC",
		},
		{
			label: "Otro",
			value: "Otro",
		},
	],
	jefatura: [
		{
			label: "Jef.Base. Brihuega-AV",
			value: "Jef.Base. Brihuega-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Calatayud-AV",
			value: "Jef.Base. Calatayud-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Montagut-AV",
			value: "Jef.Base. Montagut-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. San Feliu de Buixalleu-AV",
			value: "Jef.Base. San Feliu de Buixalleu-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jef.Base. Villafranca del Penedes-AV",
			value: "Jef.Base. Villafranca del Penedes-AV",
			parent: "G.Mto.A.V. Noreste",
		},
		{
			label: "Jefatura Premantenimiento O Irixo",
			value: "Jefatura Premantenimiento O Irixo",
			parent: "G.Mto.A.V. Noroeste",
		},
		{
			label: "Jef.Base. La Hiniesta-AV",
			value: "Jef.Base. La Hiniesta-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Olmedo-AV",
			value: "Jef.Base. Olmedo-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Villada-AV",
			value: "Jef.Base. Villada-AV",
			parent: "G.Mto.A.V. Norte",
		},
		{
			label: "Jef.Base. Gabaldon-AV",
			value: "Jef.Base. Gabaldon-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Monforte del Cid-AV",
			value: "Jef.Base. Monforte del Cid-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Requena-AV",
			value: "Jef.Base. Requena-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Villarubia-AV",
			value: "Jef.Base. Villarubia-AV",
			parent: "G.Mto.A.V. Este",
		},
		{
			label: "Jef.Base. Antequera-AV",
			value: "Jef.Base. Antequera-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Calatrava-AV",
			value: "Jef.Base. Calatrava-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Hornachuelos-AV",
			value: "Jef.Base. Hornachuelos-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Jef.Base. Mora-AV",
			value: "Jef.Base. Mora-AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Señalizacion - GEA - AV",
			value: "Señalizacion - GEA - AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "Telecomunicaciones - GEA - AV",
			value: "Telecomunicaciones - GEA - AV",
			parent: "G.Mto.A.V.Sur",
		},
		{
			label: "J.A.M. BARCELONA",
			value: "J.A.M. BARCELONA",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "J.A.M. ZARAGOZA",
			value: "J.A.M. ZARAGOZA",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "Cabecera Gerencia Noreste",
			value: "Cabecera Gerencia Noreste",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "Jef.Coord.Inver.Noreste",
			value: "Jef.Coord.Inver.Noreste",
			parent: "RC - Subdirección NORESTE",
		},
		{
			label: "J.A.M. BURGOS",
			value: "J.A.M. BURGOS",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "J.A.M. VALLADOLID",
			value: "J.A.M. VALLADOLID",
			parent: "RC - Subdirección NORTE",
		},
		{ label: "J.A.M. PAMPLONA", value: "J.M. IRÚN", parent: "RC - Subdirección NORTE" },
		{
			label: "Cabecera Gerencia Norte",
			value: "Cabecera Gerencia Norte",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "Jef.Coord.Inver.Norte",
			value: "Jef.Coord.Inver.Norte",
			parent: "RC - Subdirección NORTE",
		},
		{
			label: "J.A.M. CÓRDOBA",
			value: "J.A.M. CÓRDOBA",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.A.M. SEVILLA",
			value: "J.A.M. SEVILLA",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.M. LINARES-BAEZA",
			value: "J.M. LINARES-BAEZA",
			parent: "RC - Subdirección SUR",
		},
		{ label: "J.M. MÉRIDA", value: "J.M. MÉRIDA", parent: "RC - Subdirección SUR" },
		{
			label: "Cabecera Gerencia Sur",
			value: "Cabecera Gerencia Sur",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "Jef.Coord.Inver.Sur",
			value: "Jef.Coord.Inver.Sur",
			parent: "RC - Subdirección SUR",
		},
		{
			label: "J.A.M. VALENCIA",
			value: "J.A.M. VALENCIA",
			parent: "RC - Subdirección ESTE",
		},
		{ label: "J.M. ALBACETE", value: "J.M. ALBACETE", parent: "RC - Subdirección ESTE" },
		{
			label: "Cabecera Gerencia Este",
			value: "Cabecera Gerencia Este",
			parent: "RC - Subdirección ESTE",
		},
		{
			label: "Jef.Coord.Inver.Este",
			value: "Jef.Coord.Inver.Este",
			parent: "RC - Subdirección ESTE",
		},
		{
			label: "J.A.M. MADRID-NORTE",
			value: "J.A.M. MADRID-NORTE",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "J.A.M. MADRID-SUR",
			value: "J.A.M. MADRID-SUR",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "Cabecera Gerencia Centro",
			value: "Cabecera Gerencia Centro",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "Jef.Coord.Inver.Centro",
			value: "Jef.Coord.Inver.Centro",
			parent: "RC - Subdirección CENTRO",
		},
		{
			label: "J.A.M. ORENSE",
			value: "J.A.M. ORENSE",
			parent: "RC - Subdirección NOROESTE",
		},
		{ label: "J.A.M. LEÓN", value: "J.A.M. LEÓN", parent: "RC - Subdirección NOROESTE" },
		{
			label: "J.M. SALAMANCA",
			value: "J.M. SALAMANCA",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Cabecera Gerencia Noroeste",
			value: "Cabecera Gerencia Noroeste",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Jef.Coord.Inver.Noroeste",
			value: "Jef.Coord.Inver.Noroeste",
			parent: "RC - Subdirección NOROESTE",
		},
		{
			label: "Otro",
			value: "Otro",
			parent: "Otro",
		},
	],
	provincia: [
		"A Coruña",
		"Álava",
		"Albacete",
		"Alicante",
		"Almería",
		"Asturias",
		"Ávila",
		"Badajoz",
		"Baleares",
		"Barcelona",
		"Burgos",
		"Cáceres",
		"Cádiz",
		"Cantabria",
		"Castellón",
		"Ciudad Real",
		"Córdoba",
		"Cuenca",
		"Girona",
		"Granada",
		"Guadalajara",
		"Gipuzkoa",
		"Huelva",
		"Huesca",
		"Jaén",
		"La Rioja",
		"Las Palmas",
		"León",
		"Lérida",
		"Lugo",
		"Madrid",
		"Málaga",
		"Murcia",
		"Navarra",
		"Ourense",
		"Palencia",
		"Pontevedra",
		"Salamanca",
		"Segovia",
		"Sevilla",
		"Soria",
		"Tarragona",
		"Santa Cruz de Tenerife",
		"Teruel",
		"Toledo",
		"Valencia",
		"Valladolid",
		"Vizcaya",
		"Zamora",
		"Zaragoza",
		"Ceuta",
		"Melilla",
	],
	estado_ite: ["Programada", "En proceso", "Entregado", "Reenviado", "Registrado", "Rechazado"],
	resultado_ite: ["Pendiente", "Sin acciones", "Con acciones", "Proyecto"],
	empresa: ["CEMOSA", "UTE Viarium - Gilbartolome", "Arup", "ADIF", "Otra"],
	tipologia: defaultValuesTipologias,
	usos: defaultValuesUsos,
	especialidades: [
		"Infraestructura y Vía",
		"Telecomunicaciones",
		"Fibra óptica",
		"Instalaciones de seguridad eléctrica",
		"Instalaciones de seguridad mecánica",
		"Subestaciones",
		"Linea aérea de contacto",
	],
	relevancia: ["alta", "media", "baja"],
	estado_ficha_inventario: ["Pendiente", "Por aceptar", "Completado", "Rechazado"],
	estado_levantamiento: ["En proceso", "Entregado", "Registrado", "Rechazado"],
	por_validar: ["true", "false"],
	nee_categoria_mejoras: [
		"Características constructivas",
		"Características de la envolvente",
		"Suministros energéticos",
		"Iluminación",
		"Climatización",
		"Calefacción",
		"Refrigeración",
		"Ventilación",
		"Equipamiento",
		"Instalaciones frigoríficas",
	],
	nee_tipo_edificio: ["Uso Administrativo", "Uso mixto", "Uso industrial"],
	nec_indice: ["NCE4", "NCE3", "NCE2", "NCE1"],
	nee_indice: ["NEE4", "NEE3", "NEE2", "NEE1"],
	nae_indice: ["NAE3", "NAE2", "NAE1"],
	ncn_indice: ["NCNE3", "NCNE2", "NCNE1"],
	prioridad_reparacion: [
		"Riesgo grave o inminente",
		"Urgente (tan pronto como sea posible)",
		"Corto plazo (actuacion recomendable no mas de 12 meses)",
		"A medio plazo",
	],
};

export const defaultValues: Record<string, Choice[]> = Object.fromEntries(
	Object.entries(rawDefaults).map(([key, choices]) => [
		key,
		choices.map((choice) => (typeof choice === "string" ? { label: choice, value: choice } : choice)),
	]),
);

export const getUniqueChoices = (choices: Choice[]) => {
	const temp: Record<string, Choice> = {};
	for (const choice of choices) temp[choice.value] = choice;
	return Object.values(temp);
};
